<template>
  <div>
    <register-credential-tef ref="RegisterCredentialTef" />
    <show-credential-tef ref="ShowCredentialsTef" />
  </div>
</template>
<script>
  export default {
    components: {
      RegisterCredentialTef: () => import('./components/RegisterCredentialTef'),
      ShowCredentialTef: () => import('./components/ShowCredentialTef'),
    },
  }
</script>
